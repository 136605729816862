import styled from 'styled-components';
import {Header} from 'semantic-ui-react';
import {breakpoints, colors} from '../../theme';

// https://styled-components.com/docs/basics#attaching-additional-props
const EntryTitle = styled(Header).attrs(props => ({
  fontSize: (props.size || 'large') === 'large' ? '64px' : '28px',
  lineHeight: (props.size || 'large') === 'large' ? '76px' : '34px',
}))`
  font-family: Oswald, san-serif;
  display: block;
  position: relative;
  font-size: ${props => props.fontSize};
  line-height: ${props => props.lineHeight};
  letter-spacing: 2px;
  max-width: 90%;
  margin: 0 auto 10px;
  text-align: center;
  text-transform: uppercase;

  a {
    color: ${colors.darkTextColor};

    &:hover {
      color: #6b6e80;
    }
  }

  @media screen and (max-width: ${breakpoints.mobileMax}) {
    max-width: 100%;
    font-size: 20px;
    line-height: 28px;
  }
`;

export default EntryTitle;
