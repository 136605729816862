import styled from 'styled-components';
import {Link} from 'gatsby';
import React from 'react';

const EntryCategoryContainer = styled.span`
  position: relative;
  padding-left: 10px;
`;

const CategoryLink = styled(Link)`
  font-style: normal;
  text-transform: uppercase;

  a:last-child {
    margin-right: 0;
  }
`;

const EntryCategory = ({categoryLink, categoryTitle}) => (
  <EntryCategoryContainer>
    in{' '}
    <CategoryLink to={categoryLink || '/'}>
      {categoryTitle || '<No Category>'}
    </CategoryLink>
  </EntryCategoryContainer>
);

export default EntryCategory;
