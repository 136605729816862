import styled from 'styled-components';
import {Link} from 'gatsby';
import React from 'react';
import moment from 'moment';

const PostedOn = styled.span`
  &::after {
    content: '/';
    padding-left: 10px;
  }
`;

const EntryDate = styled.time`
  font-style: normal;
  text-transform: uppercase;
`;

const EntryPostedOn = ({articleLink, publishedAt}) => {
  const publishedDate = moment(publishedAt);
  return publishedAt ? (
    <PostedOn>
      on{' '}
      <Link to={articleLink || '/'} rel="bookmark">
        <EntryDate dateTime={publishedDate.format('YYYY-MM-DD')}>
          {publishedDate.format('ll')}
        </EntryDate>
      </Link>
    </PostedOn>
  ) : (
    'Not Published '
  );
};

export default EntryPostedOn;
