import React from 'react';
import styled from 'styled-components';
import EntryPostedOn from './EntryPostedOn';
import EntryCategory from './EntryCategory';
import {colors} from '../../theme';

const EntryMetaContainer = styled.div`
  display: block;
  text-align: center;
  font-size: 12px;
  line-height: 21px;
  font-style: italic;
  color: ${colors.mutedTextColor};

  span:first-child {
    padding-left: 0;
  }

  span {
    position: relative;
    padding-left: 10px;
  }
`;

const EntryMeta = ({articleLink, categoryTitle, categoryLink, publishedAt}) => (
  <EntryMetaContainer>
    <EntryPostedOn articleLink={articleLink} publishedAt={publishedAt} />
    <EntryCategory categoryTitle={categoryTitle} categoryLink={categoryLink} />
  </EntryMetaContainer>
);

export default EntryMeta;
